.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100vh;
  min-width: 1024px;
  min-height: 100vh;
}

.page-content {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
  //height: 100%;
  //width: 100%;
  position: relative;
  //padding-bottom: 72px;
}

.page-layout-content {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  margin-top: 8rem;
  max-width: 120rem;
  width: 100%;
}

.footer {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  bottom: 0;
  right: 12px;
  position: relative;

  .logo {
    width: 264px;
    height: 56px;
  }
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
}
