$tab-bg-color: #2C3644;
$tab-divider-color: #848A92;

.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .tabs-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
    background-color: $tab-bg-color;
    border-radius: 8px;
    margin-bottom: 30px;

    .tab-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .tab-title {
        height: 100%;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: white;
        text-transform: uppercase;
        padding: 16px;
        font-family: 'cera-pro', serif;

        &.active-tab {
          border-bottom: 1px solid $primary-color;
          color: $primary-color;
        }

        &:hover {
          color: $primary-color;
        }

      }
    }

    .tab-divider {
      height: 30px;
      width: 1px;
      background-color: $tab-divider-color;

      &:last-child {
        display: none;
      }
    }
  }

  .tabs-panel {
    display: flex;
    width: 100%;
    height: 100%;

    .tab-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
}

.tab-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px
}
