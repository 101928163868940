.profile-widget {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;

  .profile-header {
    color: #fff;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    align-items: center;

    .profile-menu-toggle {
      display: flex;
      align-items: center;
      margin: 0 23px 0 30px;
      cursor: pointer;

      .profile-avatar {
        $size: 40px;
        width: $size;
        height: $size;
        border-radius: 50%;
        border: 2px solid;

        &:hover {
          border: solid 2px $primary-color;
        }
      }

      .user-icon {
        $size: 16px;
        width: $size;
        height: $size;
        --color: #fff;
      }
    }

    .profile-details {
      position: relative;
      top: -1px;

      .user-name {
        font-weight: 800;
        font-size: 16px;
      }

      .user-email {
        position: relative;
        top: -2px;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }

  .profile-menu-container {
    position: absolute;
    $height: 60px;
    top: 50px;
    width: 100%;
    left: 0;
    pointer-events: none;
    z-index: 999;

    .menu-items-container {
      width: 100%;
      position: absolute;
      background-color: rgba(#000, 0.7);
      color: #fff;
      font-size: 12px;
      left: 10px;
      pointer-events: initial;
      padding: 10px 16px;
    }

    .menu-item {
      width: 100%;
      cursor: pointer;
      padding: 8px 0;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
