/* Theme
    * Use this theme file for client specific styles and variables.
 */

// 1. Colors - use this for custom component colors
//Core
$primary-color: #00B0CC;
$primary-color-light: #19E0FF;
$primary-color-dark: #00526a;
$secondary-color: #EB6D09;
$secondary-light-color: #FF8624;

$dark-gray: rgb(38, 46, 53); // Nothing?
$title-gray: #2f3847; // Rows with Audience boxes in
$dark-gray-audience: #252f3e; // Audience box
$light-grey-3: #0d448b; // Nothing
$off-white-background: rgba(247, 247, 248, 0.06); // Nothing
$loaded: #6dcce2;

$rhino: #49505B;
$black-perl: #1d141f;
$gunmetal: #2c333a;
$reef: #c7f296;
$wewak: #f599a8;

$background--1: #060D17;
$background-0: #0B1627;
$background-5: #162031;
$background-7: #1b2535; // Card Summary
$background-8: #1e2837;
$background-9: #212b3a;
$background-11: #252f3e; // Card Background
$background-12: #273140;
$background-14: #2c3544;
$background-15: #2f3847; // Main background
$background-16: #313a48;
$background-20: #3b4452;
$background-25: #474f5c;
$background-50: #848a92;

$black: #000;
$white: #fff;
$off-white-1: #f5f5f5;
$off-white-2: #f9f9f9;
$off-white-3: #f7f7f8;
$off-white-4: #e0e0e0;

$grey: #999999;
$light-grey-1: #d6d8da;
$light-grey-2: #d5d6d8;
$light-grey-3: #aeb1b5;
$light-grey-4: #858b91;
$light-grey-5: #9d9d9d;
$sub-grey: #5d646c;

$twitter-blue: #7fd0e4;

$core-main: #00b1cc;
$core-bright: #19E0FF;
$core-light: #82e1f2;

$comp-main: #eb6f09;
$comp-bright: #FF8624;
$comp-light: #fdcd82;

// Categorical colours for isolating groups e.g in charts
$red: #F3546D;
$yellow: #EDBE32;
$green: #4ED469;
$purple: #9D53F2;
$white: #ffffff;
$grey: #848A92;

//Gradients
$gradient-base: linear-gradient(#2f3847, #212b3a);

// 2. Fonts
$font-primary: cera-pro, sans-serif;

// Use this for material ui components styles
:root {
  --color-primary: #00B0CC;
  --dropdown-bg: #2C3644;
  --dropdown-hover: #333D4B;
  --menu-bg: #333D4B;
  --menu-hover: #454C58;
  --menu-select: #3F5766;
}
