.panel-content {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &-space {
    justify-content: space-between;
  }

  &-evenly {
    justify-content: space-around;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

}
