.gender-chart {
  position: relative;
  padding-top: 50px;
  min-width: 300px;
  max-width: 380px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-loading {
    opacity: 0.5;
  }

  .chart-label {
    font-size: 11px;
    color: $off-white-4;
    font-weight: $ibmPlexSansRegularWeight;
    font-family: 'cera-pro';
    position: absolute;
    top: 0px;

    &-male {
      left: 0;
    }

    &-female {
      right: 0
    }

  }

  .chart-segment {
    position: relative;
    min-width: 240px;
    max-width: 320px;
    width: 80%;
    flex: 1 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .segment-bar-wrapper {
      height: 12px;
      display: flex;
      position: relative;

      .bars-window {
        border-radius: 10px;
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: relative;
      }

      .bar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        transition: transform 0.3s;

        &-value-b {
          left: 100%;
          background-color: $rhino;
          opacity: 0.84;
        }
      }

      .bar-divider-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: transform 0.3s;
        transform: translateX(0);
      }

      .bar-divider {
        $height: 14px;
        $width: 2px;
        position: absolute;
        left: 0;
        outline: 2px solid rgba($background-15, 0.75);
        margin-left: -$width/2;
        background-color: $off-white-3;
        height: $height;
        width: $width;
        top: 50%;
        margin-top: -$height/2;

        .segment-value {
          position: absolute;
          font-size: 10px;
          font-weight: $ibmPlexMonoTextWeight;
          font-family: 'cera-pro';
          color: $off-white-3;
          bottom: 100%;
          width: 240px;
          text-align: right;
          pointer-events: none;
          right: 100%;
          margin-bottom: 6px;
          margin-right: -12px;

          &-female {
            left: 100%;
            text-align: left;
            right: auto;
            margin-right: auto;
            margin-left: -12px;
          }
        }
      }
    }

    .segment-name {
      font-size: 10px;
      color: $off-white-4;
      font-weight: $ibmPlexSansRegularWeight;
      font-family: 'cera-pro';
      text-align: center;
      margin-top: 7px;
    }
  }
}
