/* General
  * Use this file for general styles that are not specific to a component e.g. Spacing, Breakpoints.
*/

$page-background: #172232;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: $white;
  font-family: $font-primary;
  background: $page-background;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-space-evenly {
    justify-content: space-evenly;
    justify-items: flex-end;
  }

  &-space-between {
    justify-content: space-between;
    justify-items: flex-end;
  }

  &-end {
    justify-content: flex-end;
    align-items: center;
  }
}
