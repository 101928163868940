.navbar {
  $height: 70px;
  position: relative;
  flex: 0 0 auto;
  border: 1px solid #2f3847;
  border-bottom: 1px solid #848a92;
  height: $height;
  width: 100%;
  background-color: rgba(#0B1627, 0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .navbar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    width: 100%;

    .navbar-logo {
      padding-left: 60px;
      justify-content: flex-start;
      width: 400px;

      .logo {
        display: flex;
        width: 95px;
        height: 50px;
        position: relative;

        svg {
          flex: 1;
        }
      }
    }

    .navbar-title-text {
      text-transform: uppercase;
    }

    .navbar-menu {
      padding-right: 30px;
    }
  }
}


