.chart-container {
  min-width: 300px;
  max-width: 380px;
  height: 160px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.bar-chart {
  min-width: 300px;
  max-width: 380px;
  height: 180px;
  margin-top: 4px;
}

.radar-chart {
  width: 600px;
  height: 400px;
}

.pie-chart {
  min-width: 200px;
  max-width: 250px;
  height: 120px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
