.survey-icon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  //border-radius: 50%;
  background-size: cover;
  background-position: center;

  &-image {
    overflow: hidden;
    //position: relative;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
