.age-chart {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  .svg-gradient {
    width: 0;
    height: 0;
    position: absolute;
  }

  .tooltip-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(2px) translateY(-30px);
  }
}
